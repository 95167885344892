// "use client";

import {
  Group,
  Button,
  Text,
  Divider,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  em,
  Skeleton,
  Flex,
} from "@mantine/core";
import { LanguagePicker } from "@/components/LanguagePicker";
import { availableLanguageTags, languageTag } from "@/paraglide/runtime";
import { Logo } from "@/components/Logo";
import { UserButton } from "@clerk/nextjs";
import * as m from "@/paraglide/messages";

import { useDisclosure, useMediaQuery } from "@mantine/hooks";
// import // IconNotification,
// IconCode,
// IconBook,
// IconChartPie3,
// IconFingerprint,
// IconCoin,
// IconChevronDown,

// "@tabler/icons-react";
import classes from "./index.module.css";
import Link from "next/link";
import { useUser, useClerk } from "@clerk/nextjs";
import { useRouter } from "next/router";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const langToLanguage = (lang: string) => {
  if (lang === "en") return "English";
  if (lang === "es") return "Español";
  if (lang === "pt") return "Português";
};
export function HeaderMegaMenu() {
  const { isLoaded, isSignedIn } = useUser();
  const { signOut } = useClerk();
  const { asPath } = useRouter();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
    
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const signButtons = () => {
    return isLoaded ?   (
      <>
        {!isSignedIn && (
          <>
            <Button component={Link} variant="default" href="/sign-in">
              {m.bold_tired_baboon_lift()}
            </Button>
          </>
        )}
      </>
    ) : (
      <>
        <Skeleton height={rem(40)} width={rem(120)} />
        <Skeleton height={rem(40)} width={rem(160)} />
      </>
    );
  };

  const appLinks = [
    { label: m.factual_icy_falcon_wish(), link: "/terms" },
    { label: m.antsy_loved_newt_accept(), link: "/support" },
  ];

  const [mobileLangOpened, { toggle: toggleMobileLangOpened }] =
    useDisclosure(false);

  return (
    <Box pb={isMobile ? 0 : 120} pos="sticky" top="0" style={{ zIndex: 100 }}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%" bg="white">
          <Button variant="transparent" component={Link} href="/">
            <Logo height={28} width={100} />
          </Button>

          <Group visibleFrom="sm">
            <LanguagePicker />
            {signButtons()}
            <UserButton signInUrl="/sign-in" userProfileMode="modal" />
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="sm"
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        // title="Navigation"
        title={<Logo height={28} width={100} />}
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />
          <Group
            gap="12px"
            m="lg"
            style={{ flexDirection: "column" }}
            align="start"
          >
            <Text
              hidden={!isLoaded || !isSignedIn}
              prefetch={true}
              component={Link}
              href="/app/patients"
              fw={600}
            >
              {m.large_equal_emu_dig()}
            </Text>

            {appLinks.map(({ label, link }) => (
              <Text
                key={link}
                component={Link}
                href={{ pathname: link }}
                fw={600}
              >
                {label}
              </Text>
            ))}

            <Group display="flex" gap="xs">
              <Box maw={400} mx="auto">
                <Group>
                  <Button
                    variant="white"
                    fw={600}
                    p="0"
                    m="0"
                    c="black"
                    fz="md"
                    onClick={toggleMobileLangOpened}
                    rightSection={
                      mobileLangOpened ? (
                        <IconChevronUp size={14} />
                      ) : (
                        <IconChevronDown size={14} />
                      )
                    }
                  >
                    {m.due_many_ladybug_kick()}
                  </Button>
                </Group>

                <Collapse in={mobileLangOpened}>
                  <Flex ml="sm" gap="sm" direction="column">
                    {availableLanguageTags.map((lang) => (
                      <Text
                        key={lang}
                        component={Link}
                        href={{ pathname: asPath }}
                        locale={lang}
                        hrefLang={lang}
                        fw={600}
                        c={languageTag() === lang ? "dimmed" : "gray.8"}
                      >
                        {langToLanguage(lang)}
                      </Text>
                    ))}
                  </Flex>
                </Collapse>
              </Box>
            </Group>
          </Group>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            {isSignedIn ? (
              <>
                <Button
                  onClick={async () => {
                    await signOut();
                    closeDrawer();
                  }}
                  variant="default"
                >
                  {" "}
                  {m.extra_stale_panther_pinch()}
                </Button>
              </>
            ) : (
              <>
                <Button component={Link} variant="default" href="/sign-in">
                  {" "}
                  {m.helpful_fair_mare_play()}
                </Button>
                <Button component={Link} href="/sign-up">
                  {" "}
                  {m.upper_lost_ray_buy()}!{" "}
                </Button>
              </>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
