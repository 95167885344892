// "use client";

import { Container, Title, Text, Box, Skeleton, rem } from "@mantine/core";
import * as m from "@/paraglide/messages";
import Link from "next/link";
import classes from "./index.module.css";
import dynamic from "next/dynamic";

const Calc = dynamic(() => import("../../Calc"), {
  ssr: false,
  loading: () => (
    <Box w="100%">
      <Skeleton height={rem(250)} width="100%" />
    </Box>
  ),
});

export function CalcSection() {
  return (
    <Container id="calc-section" size="sm" className={classes.wrapper}>
      <Title ta="center" c="blue.8" fw={800} mb="sm">
        {m.direct_blue_seal_edit()}
      </Title>
      <Text c="dimmed" ta="center" mb="xl">
        {m.misty_wise_fly_ask()}
        <Text component={Link} href="/sign-up" c="blue.9">
          {" "}
          {m.red_lime_robin_mend()}{" "}
        </Text>
        {m.each_lazy_turtle_mend()}
      </Text>
      <Calc resultsTop={60} />
    </Container>
  );
}
