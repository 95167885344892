import { Title, Text, Button, Container } from "@mantine/core";
import { Dots } from "./Dots";
import classes from "./index.module.css";
import { useUser } from "@clerk/nextjs";
// import { IconPhone } from "@tabler/icons-react";
import * as m from "@/paraglide/messages.js";
import Link from "next/link";

function Hero() {
  const { isLoaded, isSignedIn } = useUser();
  return (
    <Container className={classes.wrapper} size={1400}>
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <Title
          mb="md"
          className={classes.title}
          style={{ maxWidth: 600, margin: "auto" }}
        >
          {m.such_grassy_pug_dream()}{" "}
          <Text component="span" className={classes.highlight} inherit>
            {m.house_deft_toad_hurl()}
          </Text>
        </Title>

        <Container p={0} size={600}>
          <Text size="lg" c="dimmed" className={classes.description}>
            {m.bland_dry_okapi_pick()}
          </Text>
        </Container>

        <div className={classes.controls}>
          {isLoaded && isSignedIn ? (
            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "blue.5", deg: 160 }}
              className={classes.control}
              size="lg"
              radius="xl"
              component={Link}
              href="/app/patients"
            >
              {m.large_equal_emu_dig()}
            </Button>
          ) : (
            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "blue.5", deg: 160 }}
              className={classes.control}
              size="lg"
              radius="xl"
              component={Link}
              href="/sign-up"
            >
              {m.upper_lost_ray_buy()}
            </Button>
          )}
        </div>
      </div>
      {/* <Affix position={{ bottom: 40, right: 40 }}>
        <ActionIcon color="blue" radius="xl" size={60}>
          <IconPhone stroke={1.5} size={30} />
        </ActionIcon>
      </Affix> */}
    </Container>
  );
}

export default Hero;
