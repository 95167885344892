import * as m from "@/paraglide/messages";
import { Faq } from "@/components/landing/Faq";
import { Features } from "@/components/landing/Features";
import { Footer } from "@/components/landing/Footer";
import { HeaderMegaMenu } from "@/components/landing/Header";
import { CalcSection } from "@/components/landing/CalcSection";
import Hero from "@/components/landing/Hero";
import SEO from "@/components/seo";

export default function HomePage() {
  return (
    <>
      <SEO
        title={m.short_livid_tern_enrich()}
        description={m.stale_fair_wren_drip()}
        pageSlug="/"
      />
      <HeaderMegaMenu />
      <Hero />
      <Features />
      <Faq />
      <CalcSection />
      <Footer />
    </>
  );
}
