// "use client";

import { Container, Title, Accordion } from "@mantine/core";
import classes from "./index.module.css";
import * as m from "@/paraglide/messages.js";

export function Faq() {
  const data = [
    {
      id: "1",
      question: m.smart_fancy_mammoth_sing(),
      answer: m.few_any_stork_zip(),
    },
    {
      id: "2",
      question: m.close_polite_niklas_devour(),
      answer: m.sea_bold_bulldog_work(),
    },
    {
      id: "3",
      question: m.that_patchy_crossbill_wish(),
      answer: m.every_vexed_jellyfish_stab(),
    },
    {
      id: "4",
      question: m.short_fit_platypus_ripple(),
      answer: m.yummy_strong_kudu_boost(),
    },
    {
      id: "5",
      question: m.fresh_orange_alligator_flop(),
      answer: m.livid_even_pug_amuse(),
    },
    {
      id: "6",
      question: m.flat_royal_shad_honor(),
      answer: m.every_sunny_bat_list(),
    },
    {
      id: "7",
      question: m.gray_north_quail_list(),
      answer: m.acidic_ornate_pony_reap(),
    },
  ];
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title ta="center" className={classes.title}>
        {m.smart_small_dog_launch()}
      </Title>

      <Accordion variant="separated">
        {data.map(({ id, question, answer }) => {
          return (
            <Accordion.Item key={id} className={classes.item} value={id}>
              <Accordion.Control>{question}</Accordion.Control>
              <Accordion.Panel>{answer}</Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}
