// "use client";

import {
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  IconAdjustmentsCog,
  IconReportAnalytics,
  IconDeviceHeartMonitor,
  IconGraph,
} from "@tabler/icons-react";
import classes from "./index.module.css";
import * as m from "@/paraglide/messages.js";

export function Features() {
  const mockdata = [
    {
      id: "1",
      title: m.mealy_patient_nils_dine(),
      description: m.curly_aqua_sparrow_drop(),
      icon: IconReportAnalytics,
    },
    {
      id: "2",
      title: m.sweet_witty_skunk_gaze(),
      description: m.ok_direct_koala_enjoy(),
      icon: IconAdjustmentsCog,
    },
    {
      id: "3",
      title: m.house_known_herring_fear(),
      description: m.busy_cuddly_pig_honor(),

      icon: IconDeviceHeartMonitor,
    },
    {
      id: "4",
      title: m.kind_stock_buzzard_grow(),
      description: m.white_close_chicken_fall(),
      icon: IconGraph,
    },
  ];
  const theme = useMantineTheme();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.id}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.blue[6]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      {/* <Title order={2} className={classes.title} ta="center" mt="sm">
        {m.left_alive_florian_aspire()}
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        {m.keen_chunky_mammoth_pout()}
      </Text> */}

      <SimpleGrid cols={{ base: 1, md: 2 }} spacing="xl" mt={50}>
        {features}
      </SimpleGrid>
    </Container>
  );
}
